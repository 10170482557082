$positions: (
  ("t" "top" null),
  ("r" "right" null),
  ("r-minus" "right" null),
  ("b" "bottom" null),
  ("l" "left" null),
  ("v" "top" "bottom"),
  ("h" "right" "left")
);

$degrees:
  45,
  90,
  135,
  180,
  225,
  270,
  315,
  360;

$pixels:
  0,
  2,
  3,
  4,
  5,
  -5,
  6,
  -6,
  7,
  -7,
  8,
  -8,
  9,
  10,
  -10,
  11,
  -11,
  12,
  -12,
  13,
  15,
  -15,
  17,
  18,
  19,
  20,
  -20,
  21,
  22,
  23,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  32,
  33,
  34,
  35,
  40,
  45,
  50,
  75,
  88,
  98,
  99,
  100,
  102,
  130;


@each $y in $degrees {
  .hg-rotate-#{nth($y, 1)} {
    transform: rotate3d(0, 0, 1, #{nth($y, 1)}deg) !important;
  }
}

@each $x in $pixels {
  .p-#{nth($x, 1)} {
    padding: #{nth($x, 1)}px !important;
  }
  .m-#{nth($x, 1)} {
    margin: #{nth($x, 1)}px !important;
  }
}

@each $p in $pixels {
  .fs-#{nth($p, 1)} {
    font-size: #{nth($p, 1)}px !important;
  }
}

@each $i in $positions {
  @each $x in $pixels {
    @if #{length($i)} == 2 {
      .p-#{nth($i, 1)}-#{nth($x, 1)} {
        padding-#{nth($i, 2)}: #{nth($x, 1)}px !important;
        $t: #{nth($i, 3)};
        @if str-length($t) != 0 {
          padding-#{$t}: #{nth($x, 1)}px !important;
        }
      }
      .m-#{nth($i, 1)}-#{nth($x, 1)} {
        margin-#{nth($i, 2)}: #{nth($x, 1)}px !important;
        $t: #{nth($i, 3)};
        @if str-length($t) != 0 {
          margin-#{$t}: #{nth($x, 1)}px !important;
        }
      }
    }
  }
}
